import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'
import API from './config'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ApiService from './services/api.service'
import { createI18n } from 'vue-i18n'
import { messages } from '@/assets/messages'

ApiService.init(API.URL)

const i18n = createI18n({
  legacy: false,
  locale: process.env.VUE_APP_LANG,
  fallbackLocale: 'en',
  globalInjection: true,
  messages,
})

const app = createApp(App)
app.use(store)
app.use(i18n)
app.use(router)
app.use(CoreuiVue)
app.use(VueAxios, axios)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)

app.mount('#app')
