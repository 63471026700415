import ApiService from './api.service'
import TokenService from './storage.service'

class AuthenticationError extends Error {
  constructor(errorCode, message) {
    super(message)
    this.name = this.constructor.name
    this.message = message
    this.errorCode = errorCode
  }
}

const UserService = {
  async me() {
    try {
      return await ApiService.get('auth/admin_me')
    } catch (e) {
      console.log(e)
    }
  },

  async login(username, password) {
    const data = {
      username: username,
      password: password,
    }

    try {
      await ApiService.csrfCookie()
      const response = await ApiService.post('auth/login', data)

      TokenService.saveToken(response.data.data.token)
      ApiService.setHeader()

      return response.data.data.token
    } catch (error) {
      throw new AuthenticationError(
        error.response.status,
        error.response.data.detail,
      )
    }
  },

  async filter() {
    try {
      return await ApiService.get('filter')
    } catch (error) {
      console.log(error)
    }
  },
  async filterSearch(filter) {
    try {
      const queryString = Object.keys(filter)
        .map((key) => key + '=' + filter[key])
        .join('&')
      return await ApiService.get('filter/search?filter=1&' + queryString)
    } catch (error) {
      console.log(error)
    }
  },

  async rolesUsers(id) {
    try {
      return await ApiService.get('roles/' + id + '/users')
    } catch (error) {
      console.log(error)
    }
  },

  async roles() {
    try {
      return await ApiService.get('roles')
    } catch (error) {
      console.log(error)
    }
  },

  async categories() {
    try {
      return await ApiService.get('categories')
    } catch (error) {
      console.log(error)
    }
  },

  async productsCategory(id) {
    try {
      return await ApiService.get('categories/' + id + '/products')
    } catch (error) {
      console.log(error)
    }
  },

  async products() {
    try {
      return await ApiService.get('products')
    } catch (error) {
      console.log(error)
    }
  },

  async category(id) {
    try {
      return await ApiService.get('categories/' + id + '/show/langs')
    } catch (error) {
      console.log(error)
    }
  },

  async product(id) {
    try {
      return await ApiService.get('products/' + id + '/show/langs')
    } catch (error) {
      console.log(error)
    }
  },

  async role(id) {
    try {
      return await ApiService.get('roles/' + id)
    } catch (error) {
      console.log(error)
    }
  },

  async languages() {
    try {
      return await ApiService.get('languages')
    } catch (error) {
      console.log(error)
    }
  },

  async saveRole(id, data) {
    try {
      return await ApiService.put('roles/' + id, data)
    } catch (error) {
      console.log(error)
    }
  },

  async saveCategory(data, id) {
    try {
      return await ApiService.put('categories/' + id, data)
    } catch (error) {
      console.log(error)
    }
  },

  async saveProduct(data, id) {
    try {
      return await ApiService.put('products/' + id, data)
    } catch (error) {
      console.log(error)
    }
  },

  async permissions() {
    try {
      return await ApiService.get('permissions')
    } catch (error) {
      console.log(error)
    }
  },

  async adminMenu() {
    try {
      return await ApiService.get('menus/admin')
    } catch (error) {
      console.log(error)
    }
  },

  async permissionsForSelect() {
    try {
      return await ApiService.get('permissions/select')
    } catch (error) {
      console.log(error)
    }
  },

  async categoriesForSelect() {
    try {
      return await ApiService.get('categories/select')
    } catch (error) {
      console.log(error)
    }
  },

  async users() {
    try {
      return await ApiService.get('users')
    } catch (error) {
      console.log(error)
    }
  },

  async vendorsSelect() {
    try {
      return await ApiService.get('vendors/select')
    } catch (error) {
      console.log(error)
    }
  },

  async uploadImage(productId, data) {
    try {
      const headers = {
        'Content-Type': 'multipart/form-data',
      }

      return await ApiService.post('upload/product/' + productId, data, headers)
    } catch (error) {
      console.log(error)
    }
  },

  async logout() {
    try {
      await ApiService.post('users/logout')
      TokenService.removeToken()
      TokenService.removeRefreshToken()
      ApiService.removeHeader()
    } catch (error) {
      console.log(error)
    }
  },
}

export { UserService, AuthenticationError }
