import { createStore } from 'vuex'
import { UserService } from '../services/user.service'
import router from '@/router'

export default createStore({
  state: {
    sidebarVisible: '',
    sidebarUnfoldable: false,
    roles: [],
    role: {},
    user: {},
    user_admin: {},
    users: [],
    permissions: [],
    permissionsModel: [],
    categoriesModel: [],
    permissionsSelect: [],
    categoriesSelect: [],
    filter: {},
    menuLinks: {},
    categories: [],
    products: [],
    category: {},
    langs: [],
    category_update: [],
    product_update: [],
    product: {},
    vendors: [],
    file: {},
    vendorsSelect: [],
  },
  mutations: {
    SET_REGISTER_ERRORS(state, payload) {
      state.registerError = payload
    },
    toggleSidebar(state) {
      state.sidebarVisible = !state.sidebarVisible
    },
    toggleUnfoldable(state) {
      state.sidebarUnfoldable = !state.sidebarUnfoldable
    },
    updateSidebarVisible(state, payload) {
      state.sidebarVisible = payload.value
    },

    SET_ROLES(state, payload) {
      state.roles = payload
    },

    SET_ROLE(state, payload) {
      state.role = payload
    },

    SET_FILTER(state, payload) {
      state.filter = payload
    },

    SET_USER(state, payload) {
      state.user = payload.data
    },

    SET_LANGS(state, payload) {
      state.langs = payload
    },

    SET_VENDORS(state, payload) {
      state.vendors = payload
    },

    SET_VENDORS_SELECT(state, payload) {
      state.vendorsSelect = payload
    },

    SET_CATEGORY(state, payload) {
      state.category = payload
    },

    SET_FILE(state, payload) {
      state.file = payload
    },

    SET_CATEGORY_UPDATE(state, payload) {
      const category = state.category_update.find(
        (v) => v.locale === payload.locale,
      )

      if (category) {
        state.category_update = state.category_update.map((x) =>
          x.locale === payload.locale ? { ...x, name: payload.name } : x,
        )
      } else {
        state.category_update.push({
          locale: payload.locale,
          name: payload.name,
          active: payload.active,
        })
      }
    },

    SET_PRODUCT_UPDATE(state, payload) {
      const product = state.product_update.find(
        (v) => v.locale === payload.locale,
      )

      if (product) {
        state.product_update = state.product_update.map((x) =>
          x.locale === payload.locale
            ? { ...x, name: payload.name, description: payload.description }
            : x,
        )
      } else {
        state.product_update.push({
          locale: payload.locale,
          name: payload.name,
          description: payload.description,
        })
      }
    },

    SET_CATEGORIES(state, payload) {
      state.categories = payload
    },

    SET_PRODUCTS(state, payload) {
      state.products = payload
    },

    SET_PRODUCT(state, payload) {
      state.product = payload
    },

    SET_USER_ADMIN(state, payload) {
      state.user_admin = payload.data
    },

    SET_USERS(state, payload) {
      state.users = payload
    },

    SET_PERMISSIONS(state, payload) {
      state.permissions = payload
    },

    SET_PERMISSIONS_MODEL(state, payload) {
      state.permissionsModel = payload
    },

    SET_CATEGORIES_MODEL(state, payload) {
      state.categoriesModel = payload
    },

    SET_PERMISSIONS_SELECT(state, payload) {
      state.permissionsSelect = payload
    },

    SET_CATEGORIES_SELECT(state, payload) {
      state.categoriesSelect = payload
    },

    SET_MENU_LINKS(state, payload) {
      state.menuLinks = payload
    },
  },
  actions: {
    async loginUser({ commit }, userData) {
      const response = await UserService.login(
        userData.email,
        userData.password,
      )
      if (response !== undefined) {
        await router.push('/dashboard')
      } else if (response.errors !== undefined) {
        commit('SET_REGISTER_ERRORS', response)
      }
    },

    async loadRoles({ commit }) {
      const response = await UserService.roles()
      commit('SET_ROLES', response.data.data)
    },

    async loadCategories({ commit }) {
      const response = await UserService.categories()
      commit('SET_CATEGORIES', response.data.data)
    },

    async loadProducts({ commit }) {
      const response = await UserService.products()
      commit('SET_PRODUCTS', response.data.data)
    },

    async loadProductsCategory({ commit }, id) {
      const response = await UserService.productsCategory(id)
      commit('SET_PRODUCTS', response.data.data)
    },

    async loadRoleById({ commit }, id) {
      const response = await UserService.role(id)
      commit('SET_ROLE', response.data.data)
    },

    async loadCategoryById({ commit }, id) {
      const response = await UserService.category(id)
      commit('SET_CATEGORY', response.data.data)
    },

    async loadProductById({ commit }, id) {
      const response = await UserService.product(id)
      commit('SET_PRODUCT', response.data.data)
    },

    async loadPermissions({ commit }) {
      const response = await UserService.permissions()
      commit('SET_PERMISSIONS', response.data.data)
    },

    async loadPermissionsForSelect({ commit }) {
      const response = await UserService.permissionsForSelect()
      commit('SET_PERMISSIONS_SELECT', response.data.data)
    },

    async loadCategoriesForSelect({ commit }) {
      const response = await UserService.categoriesForSelect()
      commit('SET_CATEGORIES_SELECT', response.data.data)
    },

    async loadUsers({ commit }, id) {
      let response = null

      if (id) {
        response = await UserService.rolesUsers(id)
      } else {
        response = await UserService.users()
      }

      commit('SET_USERS', response.data.data)
    },

    async loadSearchFilter({ commit }, data) {
      try {
        const response = await UserService.filterSearch(data)
        commit('SET_USERS', response.data.data)
      } catch (err) {
        console.log(err)
      }
    },

    async uploadImage({ commit }, data) {
      try {
        const response = await UserService.uploadImage(data.id, data.form)
        commit('SET_FILE', response.data.data)
      } catch (err) {
        console.log(err)
      }
    },

    async loadMenu({ commit }) {
      try {
        const response = await UserService.adminMenu()
        commit('SET_MENU_LINKS', response.data.data)
      } catch (err) {
        console.log(err)
      }
    },

    async loadLanguages({ commit }) {
      try {
        const response = await UserService.languages()
        commit('SET_LANGS', response.data.data)
      } catch (err) {
        console.log(err)
      }
    },

    async loadVendorsForSelect({ commit }) {
      try {
        const response = await UserService.vendorsSelect()
        commit('SET_VENDORS_SELECT', response.data.data)
      } catch (err) {
        console.log(err)
      }
    },

    async me({ commit }) {
      try {
        const response = await UserService.me()
        commit('SET_USER', response.data.data)
      } catch (err) {
        router.push('/')
      }
    },

    async loadFilter({ commit }) {
      try {
        const response = await UserService.filter()
        commit('SET_FILTER', response.data)
      } catch (err) {
        console.log(err)
      }
    },

    async adminMe({ commit }) {
      try {
        const response = await UserService.me()
        commit('SET_USER_ADMIN', response.data.data)
        commit('SET_MENU_LINKS', response.data.data.menuLinks)
      } catch (err) {
        console.log(err)
      }
    },

    // eslint-disable-next-line no-unused-vars
    async saveCategory({ commit, state }, data) {
      try {
        const response = await UserService.saveCategory(
          {
            ...{ active: data.active },
            ...{ categories: state.category_update },
          },
          data.id,
        )

        if (response) {
          router.push('/categories')
        }
      } catch (e) {
        console.log(e)
      }
    },

    // eslint-disable-next-line no-unused-vars
    async saveProduct({ commit, state }, data) {
      try {
        const response = await UserService.saveProduct(
          { ...data, ...{ categories: state.categoriesModel } },
          data.id,
        )

        if (response) {
          router.push('/products')
        }
      } catch (e) {
        console.log(e)
      }
    },

    // eslint-disable-next-line no-unused-vars
    async saveRole({ commit, state }, data) {
      try {
        const response = await UserService.saveRole(data?.id, {
          data: {
            name: data.name,
          },
          permissions: state.permissionsModel,
        })
        if (response) {
          router.push('/roles')
        }
      } catch (err) {
        console.log(err)
      }
    },
  },

  getters: {
    getRoles: (state) => state.roles,
    getRole: (state) => state.role,
    getPermissionsForSelect: (state) => state.permissionsSelect,
    getUsers: (state) => state.users,
    getMe: (state) => state.user,
    getAdminMe: (state) => state.user_admin,
    getPermissions: (state) => state.permissions,
    getFilter: (state) => state.filter,
    getMenuLinks: (state) => state.menuLinks,
    getCategories: (state) => state.categories,
    getCategoriesForSelect: (state) => state.categoriesSelect,
    getProducts: (state) => state.products,
    getCategory: (state) => state.category,
    getProduct: (state) => state.product,
    getVendorsForSelect: (state) => state.vendorsSelect,
    getLangs: (state) => state.langs,
  },

  modules: {},
})
