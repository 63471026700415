export const messages = {
  en: {
    message: {
      login: 'Log in',
      email: 'E-Mail',
      username: 'Username',
      type: 'Type',
      filter: 'Filter',
      name: 'Name',
      points: 'Points',
      description: 'Description',
      count: 'count',
      action: 'Action',
      role: 'Role',
      sku: 'SKU',
      main: 'Main product',
      variant: 'product variant',
      active: 'Active',
      vendor: 'Vendor',
      images: 'Images',
      upload_text: 'Product-images upload',
      breadcrumb: {
        user: 'User',
        home: 'Home',
        perms: 'Permissions',
        roles: 'Roles',
        dashboard: 'Dashboard',
        categories: 'Categories',
        role: 'Role',
        category: 'Category',
        products: 'Products',
        product: 'Product',
        product_category: 'Category-Products',
      },
      categories: 'Categories',
      count_users: 'Count users',
      count_perms: 'Count permissions',
      count_products: 'Count permissions',
      product: 'Product',
    },
  },
  de: {
    message: {
      login: 'Einloggen',
      email: 'E-Mail',
      username: 'Benutzer',
      filter: 'Filtern',
      name: 'Name',
      points: 'Punkte',
      sku: 'SKU',
      count: 'Anzahl',
      action: 'Aktion',
      active: 'Aktiv',
      type: 'Typ',
      main: 'Hauptprodukt',
      vendor: 'Hersteller',
      variant: 'Produktvariante',
      description: 'Beschreibung',
      upload_text: 'Produktbilder-Upload',
      images: 'Bilder',
      breadcrumb: {
        user: 'Benutzer',
        home: 'Home',
        perms: 'Rechte',
        roles: 'Rollen',
        dashboard: 'Dashboard',
        categories: 'Kategorien',
        role: 'Rolle',
        category: 'Kategorie',
        products: 'Produkte',
        product: 'Produkt',
        product_category: 'Kategorie-Produkte',
      },
      role: 'Benutzer',
      categories: 'Kategorien',
      count_users: 'Anzahl Benutzer',
      product: 'Produkt',
      count_perms: 'Anzahl Rechte',
      count_products: 'Anzahl Produkte',
    },
  },
}
