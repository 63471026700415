import { createRouter, createWebHashHistory } from 'vue-router'

import DefaultLayout from '@/layouts/DefaultLayout'

const routes = [
  {
    path: '/',
    component: () => import('@/views/pages/Login'),
  },
  {
    path: '/dashboard',
    name: 'home',
    component: DefaultLayout,
    children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      {
        path: '/roles',
        name: 'roles',
        component: () => import('@/views/pages/Roles.vue'),
      },
      {
        path: '/roles/:hashid',
        name: 'role',
        component: () => import('@/views/pages/Role.vue'),
      },
      {
        path: '/users',
        name: 'user',
        component: () => import('@/views/pages/Users.vue'),
      },
      {
        path: '/permissions',
        name: 'perms',
        component: () => import('@/views/pages/Permissions.vue'),
      },
      {
        path: '/categories',
        name: 'categories',
        component: () => import('@/views/pages/category/List.vue'),
      },

      {
        path: '/products',
        name: 'products',
        component: () => import('@/views/pages/product/List.vue'),
      },

      {
        path: '/products/:id',
        name: 'product',
        component: () => import('@/views/pages/product/Edit.vue'),
      },

      {
        path: '/categories/:id',
        name: 'category',
        component: () => import('@/views/pages/category/Edit.vue'),
      },
      {
        path: '/categories/:id/products',
        name: 'product_category',
        component: () => import('@/views/pages/product/List.vue'),
      },
      {
        path: '/roles/:id/users',
        name: 'roles-user',
        component: () => import('@/views/pages/Users.vue'),
      },
    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    // always scroll to top
    return { top: 0 }
  },
})

export default router
