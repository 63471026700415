<template>
  <div>
    <CSidebar visible position="fixed">
      <CSidebarBrand>Dashboard</CSidebarBrand>
      <CSidebarNav>
        <div v-for="link in links" :key="link.id">
          <li class="nav-title" v-if="link.link === false">
            {{ link.title }}
          </li>
          <CNavItem :href="'/#' + link.link" v-else>
            <CIcon customClassName="nav-icon" :icon="link.icon" />
            {{ link.title }}
          </CNavItem>
        </div>
      </CSidebarNav>
    </CSidebar>
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <div class="body flex-grow-1 px-3">
        <CContainer lg>
          <router-view />
        </CContainer>
      </div>
      <AppFooter />
    </div>
  </div>
</template>
<script>
import { CContainer } from '@coreui/vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import { computed } from 'vue'

export default {
  name: 'DefaultLayout',
  components: {
    AppFooter,
    AppHeader,
    CContainer,
  },

  setup() {
    const store = useStore()
    const route = useRoute()

    store.dispatch('me')

    store.dispatch('loadMenu')

    const interval = setInterval(() => {
      store.dispatch('me')
    }, 50000)

    return {
      interval,
      route,
      links: computed(() => store.getters.getMenuLinks),
    }
  },
}
</script>
